/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'spinner';

html{
  overflow: hidden;
  height: 100%;
}
body {
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  position: relative;

}



@font-face {
  font-family: Helvetica;
  src: url(assets/fonts/Helvetica/Helvetica.ttf) format("opentype");
}

body {
  margin: 0;
  font-family: "Helvetica", sans-serif !important;
}

@import '@angular/material/theming';

$custom-typography: mat.define-legacy-typography-config(
        $font-family: 'Helvetica',
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();


:root {

  --aism-primary-orange: #fcaf05;

}

/* For use in src/lib/core/theming/_palette.scss */
$aism-primary: (
  50 : #fff5e1,
  100 : #fee7b4,
  200 : #fed782,
  300 : #fdc750,
  400 : #fcbb2b,
  500 : #fcaf05,
  600 : #fca804,
  700 : #fb9f04,
  800 : #fb9603,
  900 : #fa8601,
  A100 : #ffffff,
  A200 : #fff6ed,
  A400 : #ffdbba,
  A700 : #ffcea1,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$my-primary: mat.define-palette($aism-primary);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-primary,
        warn: $my-warn,
      )));
@include mat.all-legacy-component-themes($my-theme);


.title {

  font-weight: bolder !important;
  text-transform: uppercase !important;
  font-size: 35px !important;
  line-height: 35px;

}


.subtitle {

  font-weight: normal;
  font-size: 25px !important;
  color: black !important;

}
.titleButton {

  font-weight: bolder !important;
  text-transform: uppercase !important;
  font-size: 35px !important;
}

.mat-raised-button {

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 10px !important;
  min-height: 40px!important;

}

@media only screen and (max-width: 650px) {

  .title {

    font-size: 18px !important;
    line-height: 25px;


  }
  .titleButton{

    font-size: 18px !important;

  }

  .subtitle {

    font-size: 16px !important;

  }

}

@media (min-height: 950px) {

  .title {

    font-size: 30px !important;
    line-height: 30px!important;


  }
  .subtitle{

    line-height: 30px!important;


  }
  .titleButton{

    font-size: 21px !important;

  }



}

.black-text {

  color: black !important;

}

.orange-text {

  color: var(--aism-primary-orange);

}

.white-overlay {

  background-color: white;

}

.timer-dialog-cntr {

  .mat-dialog-container {

    box-shadow: none !important;
    overflow: hidden!important;

  }

}

mat-progress-bar {

  .mat-progress-bar-buffer,
  &.mat-progress-bar,
  .mat-progress-bar-fill::after,
  .mat-progress-bar-background {

    border-radius: 10px;

  }

}

.generic-icon {

  width: 23px;
  margin: auto;
  margin-right: 10px;
  vertical-align: text-top !important;

}

@media (min-width:769px) {

  .generic-icon {

    width: 36px;

  }

}
button {

  .mat-button-focus-overlay{
    display: none!important;
  }

}

.generic-button{

  height: 40px;
  line-height: 0px!important;
  min-height: 40px;

display: flex;
justify-content: center;
align-items: center;
}
.padding-top{

  padding-top: 40px;

}
@media (min-height : 950px){
  .padding-top{

    padding-top: 0px;

  }
}

.mat-drawer-container{

  background-color: white!important;

}

@media (max-width : 370px){

  .titleButton {

    font-size: 15px!important;

  }

}

.bolder{

  font-weight: bolder!important;

}

.istructions-cntr{

  height: 70vh;
  max-height: 650px;
  background-color: #F4F4F4;
  display: flex!important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  width: 70vw;
  max-width: 700px;
}


@media (max-height : 900px){

  .istructions-cntr{
  height: 100%;

  }
}

@media (max-width : 360px){

  .istructions-cntr{

    .subtitle{

      font-size: 14px!important;

    }


  }
}

@media (min-height : 1100px){

  .istructions-cntr{

    max-height: 850px;

  }
}




.dots-istructions-width{

  position: absolute;
  bottom: 10px;
  width: 30%;

}
.mat-card-istruction-content-cntr{

  max-width: 600px;
  width: 80%;

}


@media (max-width : 600px){

  .mat-card-istruction-content-cntr{

    width: 98%;

  }
}

.grid-istruction-number-cntr {


  margin: auto;

  .bolder{
    font-size: 20px;
  }


  .border-selected{

      background-color: #ffcf67!important;

  }





}
@media  (min-height : 950px){

  .margin-top-100-tablet{

    margin-top: 100px!important;

  }
  .margin-top-50-tablet{

    margin-top: 50px!important;

  }
  .margin-bot-100-tablet{

    margin-bottom: 100px!important;

  }
  .margin-bot-50-tablet{

    margin-bottom: 50px!important;

  }


}

